<template>
    <div>
        <v-card id="details" :loading="loading2 && loading" height="100%">
            <v-card-title ref="title">
                <v-btn small icon @click="close" color="primary" class="mr-2">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
                <h3>
                    {{
                        !editPreDesign
                            ? $vuetify.breakpoint.smAndDown
                                ? 'New PD'
                                : `New Pre Design (V${
                                      this.versionNumber
                                          ? this.versionNumber
                                          : ''
                                  })`
                            : $vuetify.breakpoint.smAndDown
                            ? 'Edit PD'
                            : `Edit Pre Design (V${
                                  originalPreDesign &&
                                  originalPreDesign.versionNumber
                                      ? originalPreDesign.versionNumber
                                      : 1
                              })`
                    }}
                    - {{ cost.reference }}
                </h3>

                <v-btn
                    v-if="preDesign.rejectionReasons"
                    small
                    icon
                    @click="rejectionTable = true"
                    color="red"
                    class="ml-2"
                >
                    <v-icon>
                        mdi-book-cancel-outline
                    </v-icon>
                </v-btn>
                <v-btn
                    v-if="
                        originalPreDesign && originalPreDesign.rejectionReasons
                    "
                    small
                    icon
                    @click="rejectionTable = true"
                    color="red"
                    class="ml-2"
                >
                    <v-icon>
                        mdi-book-cancel-outline
                    </v-icon>
                </v-btn>
                <v-btn @click="print" small icon color="primary" class="ml-1">
                    <v-icon> mdi-file-pdf-box </v-icon>
                </v-btn>
                <v-btn
                    @click="importDialog = true"
                    small
                    icon
                    color="primary"
                    class="ml-2"
                >
                    <v-icon> mdi-import </v-icon>
                </v-btn>
                <v-spacer />

                <v-btn
                    rounded
                    small
                    :loading="loading2"
                    color="primary"
                    class="mx-2"
                    @click="savePreDesign"
                    :disabled="form"
                >
                    SAVE
                </v-btn>
                <v-btn
                    v-if="originalPreDesign && originalPreDesign.versionNumber"
                    small
                    icon
                    @click="openAlertDialog"
                    color="primary"
                    class="ml-2"
                    :disabled="validatePreDesign"
                >
                    <v-icon>
                        mdi-send
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text class="pt-0">
                <v-form v-model="valid" class="py-2">
                    <v-row no-gutters class="mt-4" ref="projectSelector">
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-text-field
                                disabled
                                v-model="cost.reference"
                                prepend-icon="mdi-account-hard-hat"
                                label="Scope"
                                readonly
                                hide-details
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-text-field
                                disabled
                                v-model="cost.quote.name"
                                prepend-icon="mdi-account-hard-hat"
                                label="Quote"
                                readonly
                                hide-details
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="4"
                            class="pl-2"
                        >
                            <v-text-field
                                disabled
                                v-model="formDate"
                                prepend-icon="mdi-calendar-month"
                                label="Format Date"
                                readonly
                                hide-details
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-text-field
                                disabled
                                v-model="engineer"
                                prepend-icon="mdi-account-hard-hat"
                                label="Engineer"
                                readonly
                                hide-details
                            />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="my-1">
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-text-field
                                disabled
                                v-model="estimator"
                                prepend-icon="mdi-account-hard-hat"
                                label="Estimator"
                                readonly
                                hide-details
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="4"
                            class="pl-2"
                        >
                            <v-text-field
                                disabled
                                v-model="engineeringDate"
                                prepend-icon="mdi-calendar-month"
                                label="Eng Due Date"
                                readonly
                                hide-details
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-text-field
                                disabled
                                v-model="location"
                                prepend-icon="mdi-account-hard-hat"
                                label="Location"
                                readonly
                                hide-details
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-autocomplete
                                v-model="structuralSet"
                                :items="['RECEIVED', 'NO RECEIVED']"
                                required
                                prepend-icon="mdi-cube-outline"
                                label="STRUCTURAL SET"
                                hide-details
                                attach
                            />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-0">
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-autocomplete
                                v-model="windTunnelReport"
                                :items="['RECEIVED', 'NO RECEIVED']"
                                prepend-icon="mdi-weather-windy"
                                label="WIND TUNNEL RWDI REPORT"
                                required
                                attach
                                hide-details
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-text-field
                                v-model="windCodeStd"
                                prepend-icon="mdi-weather-windy"
                                label="WIND CODE STANDARDS"
                                hide-details
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            xl="6"
                            lg="6"
                            md="6"
                            sm="6"
                            class="pl-2"
                        >
                            <v-text-field
                                v-model="notes"
                                prepend-icon="mdi-note-text"
                                label="Notes"
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </v-form>

                <v-row no-gutters class="scrollable-div" justify="center">
                    <v-card
                        width="100%"
                        class="d-flex justify-center align-center mt-5 "
                        flat
                    >
                        <v-data-table
                            id="virtual-scroll-table"
                            :headers="windCriteriaHeaders"
                            :items="criteriaFields"
                            class="custom-table elevation-0"
                            :mobile-breakpoint="0"
                            :loading="loading2"
                            disable-pagination
                            hide-default-footer
                            :style="`height: ${height}px`"
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    class="mx-0"
                                    style="background-color: #eeeeee"
                                >
                                    <v-col cols="3">
                                        <h1>Wind conditions</h1>
                                    </v-col>
                                </v-row>
                            </template>

                            <!--HEADERS-->
                            <!-- ITEMS -->
                            <template
                                v-slot:[`item.criteria`]="{
                                    item,
                                }"
                            >
                                <span> {{ item.criteria }}</span>
                            </template>
                            <template
                                v-slot:[`item.unit`]="{
                                    item,
                                    index,
                                }"
                            >
                                <v-row justify="center">
                                    <div>
                                        <v-autocomplete
                                            ref="autocomplete"
                                            :menu-props="
                                                index >=
                                                criteriaFields.length - 2
                                                    ? {
                                                          minWidth: '89px',
                                                          nudgeTop: '100px',
                                                          nudgeRight: '80px',
                                                      }
                                                    : {}
                                            "
                                            attach
                                            v-model="item.unit"
                                            :items="
                                                [0, 1].includes(index)
                                                    ? velocityUnits
                                                    : [2, 3].includes(index)
                                                    ? ['category']
                                                    : pressureUnits
                                            "
                                            :placeholder="
                                                [2, 3].includes(index)
                                                    ? 'Category'
                                                    : ''
                                            "
                                            :rules="
                                                item.value &&
                                                ![2, 3].includes(index)
                                                    ? [rules.required]
                                                    : []
                                            "
                                            :disabled="
                                                [2, 3].includes(index) &&
                                                    !!item.value
                                            "
                                            style="width: 92px;"
                                        />
                                        <!-- <span v-else>{{ item.unit }}</span> -->
                                    </div>
                                </v-row>
                            </template>
                            <template
                                v-slot:[`item.value`]="{
                                    item,
                                    index,
                                }"
                            >
                                <v-row justify="end">
                                    <div>
                                        <v-autocomplete
                                            v-if="[2, 3].includes(index)"
                                            v-model="item.value"
                                            :items="
                                                index == 2
                                                    ? riskCategories
                                                    : exposureCategories
                                            "
                                            style="width: 60px;"
                                            :menu-props="{
                                                nudgeTop: '100px',
                                                nudgeRight: '75px',
                                            }"
                                            :rules="
                                                item.unit
                                                    ? [rules.required]
                                                    : []
                                            "
                                            attach
                                        />
                                        <v-text-field
                                            v-else
                                            v-model="item.value"
                                            dense
                                            class="pt-2 text-right"
                                            :style="{
                                                width: '60px',
                                                align: 'right',
                                                justify: 'end',
                                            }"
                                            :rules="
                                                item.unit && item.unit != ''
                                                    ? [
                                                          rules.required,
                                                          rules.optionalNumber,
                                                      ]
                                                    : [rules.optionalNumber]
                                            "
                                        />
                                    </div>
                                </v-row>
                            </template>
                            <template
                                v-slot:[`item.comments`]="{
                                    item,
                                }"
                            >
                                <v-row justify="center">
                                    <div>
                                        <v-text-field
                                            v-model="item.comments"
                                            dense
                                            class="pt-2"
                                            :style="{
                                                width: '250px',
                                            }"
                                        />
                                    </div>
                                </v-row>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-row>
                <div class="mt-5">
                    <NewStructuralElementForm
                        v-for="element in structuralElements"
                        :key="element.type"
                        :createElement="true"
                        :element="element"
                        :elements="preDesign.elements"
                        :itemElements="preDesign.elements"
                        @saveItems="saveElementItems"
                        @deleteItems="deleteElementItems"
                    />
                </div>
                <div class="mt-5 mb-15">
                    <vue-editor
                        ref="editor"
                        :style="`height:${this.height}px`"
                        class="mt-2"
                        :editor-toolbar="customToolbar"
                        placeholder="Put your images here"
                        v-model="editorContent"
                    />
                </div>
            </v-card-text>
        </v-card>
        <!--Alert preDesign-->
        <v-dialog
            v-model="alertDialog"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading2" v-if="alertDialog && cost.engineering">
                <v-card-title class=" text-h5">
                    <v-btn
                        small
                        icon
                        @click="closeAlertDialog"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    Finalize Engineering</v-card-title
                >
                <v-card-text>
                    If you have completed the task, please record the time in
                    hours you required to complete the engineering process

                    <v-form v-model="isFormValid" class="py-2">
                        <v-row>
                            <v-col class="pl-2">
                                <v-text-field
                                    v-model="cost.category"
                                    prepend-icon="mdi-format-list-group"
                                    label="Category"
                                    readonly
                                    hide-details
                                    disabled
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pl-2">
                                <v-text-field
                                    v-model="hours"
                                    prepend-icon="mdi-clock-outline"
                                    label="Hours"
                                    :rules="[
                                        rules.required,
                                        rules.validateNumber,
                                    ]"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        @click="generatePreDesign"
                        text
                        color="primary"
                        :disabled="!isFormValid"
                        :loading="loading2"
                    >
                        SEND TO DONE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Alert importPreDesign-->
        <v-dialog
            v-model="importDialog"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading2" v-if="importDialog">
                <v-card-title class=" text-h5">
                    <v-btn
                        small
                        icon
                        @click="importDialog = false"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    Import preDesign
                </v-card-title>
                <v-card-text>
                    Select a preDesign

                    <v-form v-model="isFormValid2" class="py-2">
                        <v-row>
                            <v-col class="pl-2">
                                <v-select
                                    v-model="selectedImport"
                                    :items="keys"
                                    prepend-icon="mdi-clock-outline"
                                    label="preDesign..."
                                    :rules="[rules.required]"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        @click="importPreDesign(selectedImport)"
                        text
                        color="primary"
                        :disabled="!isFormValid2"
                        :loading="loading2"
                    >
                        IMPORT
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Errors />
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import moment from 'moment'
import _ from 'lodash'
import { printPreDesignPDF } from '@/components/Engineering/preDesignPDF.js'
import { VueEditor } from 'vue2-editor'
import { objDiff } from '@/helpers/objDiff.js'
import { v4 as uuidv4 } from 'uuid'
import { saveFile } from '@/services/storage/saveFile.js'
import { storage } from '@/services/firebase'

export default {
    name: 'PreDesignForm',
    props: {
        cost: {
            type: Object,
            required: false,
            default: () => {},
        },
        originalPreDesign: {
            type: Object,
            required: false,
            default: () => {},
        },
        editPreDesign: {
            type: Boolean,
            required: true,
            default: () => false,
        },
        loading2: {
            type: Boolean,
            required: true,
            default: () => false,
        },
        companyId: {
            type: String,
            required: true,
            default: '',
        },
        rejectedPreDesign: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        VueEditor,
        Errors: () => import('@/components/Layout/Errors'),
        NewStructuralElementForm: () =>
            import('@/components/Engineering/NewStructuralElementForm'),
    },
    data: () => ({
        loading: false,
        rules: {
            required: v => !!v || 'Required',
            number2: v => v > 0 || 'Error',
            number: v => v >= 0 || 'Error',
            optionalNumber: v => !v || v == '' || v > 0 || 'Invalid number',
            validateNumber: v => {
                const numberValue = parseFloat(v)
                return (
                    (!isNaN(numberValue) && numberValue > 0) || 'Invalid number'
                )
            },
        },
        isFormValid: false,
        windCriteriaHeaders: [
            {
                text: 'Wind criteria',
                value: 'criteria',
                width: 90,
                align: 'left',
                sortable: false,
            },
            {
                text: 'Unit*',
                value: 'unit',
                width: 30,
                align: 'center',
                sortable: false,
            },
            {
                text: 'Value',
                value: 'value',
                width: 30,
                align: 'right',
                sortable: false,
            },
            {
                text: 'Comments',
                value: 'comments',
                width: 250,
                align: 'center',
                sortable: false,
            },
        ],
        criteriaFields: [
            {
                criteria: 'Ultimate Wind Speed',
                unit: '',
                value: '',
            },
            {
                criteria: 'Nominal Wind Speed',
                unit: '',
                value: '',
            },
            {
                criteria: 'Risk Category',
                unit: '',
                value: '',
            },
            {
                criteria: 'Exposure Category',
                unit: '',
                value: '',
            },
            {
                criteria: 'Ultimate Wind Pressure',
                unit: '',
                value: '',
            },
            {
                criteria: 'Nominal Wind Pressure',
                unit: '',
                value: '',
            },
        ],
        velocityUnits: ['mph', 'km/h'],
        pressureUnits: ['psf', 'kg/m^2'],
        riskCategories: ['I', 'II', 'III', 'IV'],
        exposureCategories: ['A', 'B', 'C', 'D'],
        structuralElements: [
            {
                type: 'PANELS',
                fields: [
                    'unit',
                    'thkUnit',
                    'dimensionsX',
                    'dimensionsY',
                    'THK',
                    'deflection',
                ],
            },
            {
                type: 'MULLIONS',
                fields: [
                    'unit',
                    'thkUnit',
                    'detailUnit',
                    'dimensionsX',
                    'dimensionsY',
                    'THK',
                    'max_span',
                    'deflection',
                ],
            },
            {
                type: 'BRACKET',
                fields: [
                    'unit',
                    'thkUnit',
                    'dimensionsX',
                    'dimensionsY',
                    'dimensionsL',
                    'THK',
                ],
            },
            { type: 'FASTENERS', fields: ['specification', 'qty'] },
            {
                type: 'ANCHORS',
                fields: ['detailUnit', 'specification', 'embedment'],
            },
            {
                type: 'OTHERS',
                fields: [
                    'unit',
                    'thkUnit',
                    'detailUnit',
                    'dimensionsX',
                    'dimensionsY',
                    'dimensionsL',
                    'THK',
                    'max_span',
                    'embedment',
                    'deflection',
                    'specification',
                    'qty',
                ],
            },
        ],
        height: 400,
        valid: false,
        fieldsValidate1: true,
        fieldsValidate2: true,
        fieldsValidate3: true,
        fieldsValidate4: true,
        engineer: null,
        estimator: null,
        engineeringDate: null,
        users: [],
        versionNumber: '',
        dateToday: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        structuralSet: '',
        windTunnelReport: '',
        windCodeStd: '',
        notes: '',
        preDesignForm: false,
        preDesign: {},
        code: 'F09-PRED-01',
        formDate: '22/10/2021',
        localImages: {},
        name: '',
        hours: null,
        customToolbar: [['link', 'image']],
        images: [],
        editorContent: '',
        alertDialog: '',
        downloadedFiles: [],
        imagesOnBase64: [],
        imagesWithDimensions: [],
        imagesAsFiles: [],
        originalImages: [],
        importDialog: false,
        importOptions: {},
        selectedImport: null,
        isFormValid2: false,
        location: '',
    }),
    watch: {
        editorContent: async function() {
            this.imagesOnBase64 = this.extractImagesWithMatchAll(
                this.editorContent
            )
        },
        imagesOnBase64: async function(newImages) {
            if (!newImages || newImages.length === 0) return
            try {
                const imagesWithDimensions = await this.getImagesDimensions(
                    newImages
                )
                const filesArray = await this.convertToFiles(
                    imagesWithDimensions
                )
                this.imagesWithDimensions = imagesWithDimensions
                this.imagesAsFiles = filesArray
            } catch (error) {
                console.error('An error has occurred.', error)
            }
        },
        notes(newValue) {
            if (this.preDesign.notes !== newValue) {
                this.fieldsValidate1 = false
            } else {
                this.fieldsValidate1 = true
            }
        },
        structuralSet(newValue) {
            if (this.preDesign.structuralSet !== newValue) {
                this.fieldsValidate2 = false
            } else {
                this.fieldsValidate2 = true
            }
        },
        windTunnelReport(newValue) {
            if (this.preDesign.windTunnelReport !== newValue) {
                this.fieldsValidate4 = false
            } else {
                this.fieldsValidate4 = true
            }
        },
        windCodeStd(newValue) {
            if (this.preDesign.windCodeStd !== newValue) {
                this.fieldsValidate3 = false
            } else {
                this.fieldsValidate3 = true
            }
        },
    },
    computed: {
        validatePreDesign: function() {
            let diff = objDiff(this.originalPreDesign, this.preDesign)

            return Object.keys(diff).length > 0 || !this.valid || this.form
        },
        form() {
            return (
                !this.criteriaFields.every(item => {
                    const index = this.criteriaFields.findIndex(c => c == item)
                    if (item.value && ![2, 3].includes(index)) {
                        return item.unit
                    } else if (item.unit && ![2, 3].includes(index)) {
                        return item.value
                    } else {
                        return true
                    }
                }) &&
                this.criteriaFields.every(item => {
                    const index = this.criteriaFields.findIndex(c => c == item)
                    item == this.originalPreDesign.criteriaFields[index]
                })
            )
        },
        keys() {
            return Object.keys(this.importOptions)
        },
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            this.engineer = this.cost.engineer
                ? this.getUserName(this.cost.engineer)
                : ''
            this.estimator = this.cost.quote.estimatorId
                ? this.getUserName(this.cost.quote.estimatorId)
                : ''
            this.engineeringDate = this.cost.engineeringDate?._seconds
                ? this.formatDate(this.cost.engineeringDate._seconds)
                : ''
            if (this.editPreDesign && this.originalPreDesign) {
                this.preDesign = _.cloneDeep(this.originalPreDesign)
                this.criteriaFields = this.preDesign.criteriaFields
                    ? this.preDesign.criteriaFields
                    : this.criteriaFields
                this.structuralSet = this.preDesign.structuralSet
                this.windTunnelReport = this.preDesign.windTunnelReport

                this.windCodeStd = this.preDesign.windCodeStd

                this.notes = this.preDesign.notes
                if (this.rejectedPreDesign) {
                    this.versionNumber =
                        this.cost.preDesign.length > 0
                            ? this.cost.preDesign.length + 1
                            : 1
                    delete this.preDesign.versionNumber
                } else {
                    const index =
                        this.cost.preDesign && this.cost.preDesign.length > 0
                            ? this.cost.preDesign.findIndex(
                                  doc =>
                                      doc.versionNumber ==
                                      this.originalPreDesign.versionNumber
                              )
                            : -1
                    if (index > -1) {
                        this.versionNumber = this.originalPreDesign
                            .versionNumber
                            ? this.originalPreDesign.versionNumber
                            : ''
                    }
                }
                this.location = `${
                    this.cost.quote.city && this.cost.quote.state
                        ? `${this.cost.quote.city}, ${
                              this.cost.quote.state ? this.cost.quote.state : ''
                          }`
                        : this.cost.quote.state
                        ? this.cost.quote.state
                        : ''
                }`
                this.preDesign.images = this.preDesign.images
                    ? this.preDesign.images
                    : []
            } else {
                this.preDesign.elements = []
                this.preDesign.images = []
                this.versionNumber =
                    this.cost.preDesign.length > 0
                        ? this.cost.preDesign.length + 1
                        : 1
            }
            this.name =
                this.originalPreDesign && this.originalPreDesign.name
                    ? this.originalPreDesign.name
                    : `${this.cost.reference} - Version ${
                          this.versionNumber ? this.versionNumber : ''
                      }`
            // Listener for click on images
            if (this.$refs.editor) {
                const editor = this.$refs.editor.quill
                editor.root.addEventListener('click', event => {
                    if (event.target.tagName == 'IMG') {
                        const imgSrc = event.target.src
                        const imgFile = this.imagesAsFiles.find(
                            image => image.base64 === imgSrc
                        ).src
                        // Verify if src is base64
                        if (imgSrc.startsWith('data:image')) {
                            window.open(URL.createObjectURL(imgFile), '_blank') // Open new tab
                        } else if (imgSrc.startsWith('https')) {
                            window.open(imgSrc)
                        }
                    }
                })
            }
            if (!this.cost.preDesign) {
                this.cost.preDesign = []
            }
            await this.loadImages()
            this.importOptions = this.cost.preDesign
                ? this.cost.preDesign.reduce((acc, item) => {
                      acc[item.name] = item
                      return acc
                  }, {})
                : {}
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        extractImagesWithMatchAll(htmlContent) {
            const regex = /<img\s+[^>]*src="([^"]+)"(?:[^>]*data-name="([^"]+)")?[^>]*>/g

            // Convertir las coincidencias en un arreglo
            const extractedImages = Array.from(htmlContent.matchAll(regex)).map(
                match => ({
                    base64: match[1], // Contenido Base64
                    name: match[2] || '', // Nombre o valor por defecto
                })
            )
            return extractedImages
        },
        generateEditorContent() {
            let htmlContent = '<p>'
            this.preDesign.images.forEach(
                img =>
                    (htmlContent += `<img src="${img.base64}" data-name="${img.name}" alt="${img.name}"/>`)
            )
            htmlContent = `${htmlContent}</p>`

            // Asignar el HTML generado al modelo del editor
            this.editorContent = htmlContent
        },
        getUserName(id) {
            if (this.users.length > 0 && id) {
                return this.users.find(user => user.id == id)?.name
            } else {
                return ''
            }
        },
        async loadImages() {
            try {
                if (this.preDesign.images) {
                    for (let img of this.preDesign.images) {
                        if (img) {
                            const path = `${this.companyId}/quotes/${this.cost.quoteId}/costs/${this.cost.document_id}/preDesigns`
                            const storageRef = storage().ref(path)
                            const docRef = storageRef.child(img.name)

                            let url
                            await docRef
                                .getDownloadURL()
                                .then(response => {
                                    url = response
                                })
                                .catch(error => {
                                    console.log('error', error)
                                    url = ''
                                })

                            await this.toDataUrl(img, url)
                        }
                    }
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        handleLoadBlob(img, base64) {
            const index = this.preDesign.images.findIndex(image => img == image)
            if (index > -1) {
                this.preDesign.images[index].base64 = base64
            }
            if (this.preDesign.images.every(image => image.base64)) {
                this.generateEditorContent()
            }
        },
        toDataUrl(img, url) {
            var xhr = new XMLHttpRequest()
            xhr.responseType = 'blob'
            xhr.onload = () => {
                var reader = new FileReader()
                reader.onloadend = () => {
                    if (reader.result) {
                        this.handleLoadBlob(img, reader.result)
                    }
                }
                reader.readAsDataURL(xhr.response)
            }
            xhr.open('GET', url)

            xhr.send()
        },
        async getImagesDimensions(base64Images) {
            return Promise.all(
                base64Images.map(image => {
                    return new Promise((resolve, reject) => {
                        const img = new Image()
                        img.onload = () => {
                            resolve({
                                src: image.base64,
                                name: image.name,
                                width: img.width,
                                height: img.height,
                            })
                        }
                        img.onerror = () => {
                            reject(
                                `Error loading base64 image: ${image.base64}`
                            )
                        }
                        img.src = image.base64
                    })
                })
            )
        },
        async convertToFiles(imagesWithDimensions) {
            return Promise.all(
                imagesWithDimensions.map(async imageObj => {
                    const { src, width, height, name } = imageObj
                    const file = await this.base64ToFile(src)
                    const url = URL.createObjectURL(file)
                    return { src: file, width, height, url, base64: src, name }
                })
            )
        },

        // Convierte una imagen base64 a un archivo (File)
        base64ToFile(base64) {
            return new Promise(resolve => {
                const byteString = Uint8Array.from(
                    atob(base64.split(',')[1]),
                    c => c.charCodeAt(0)
                )
                const mimeString = base64
                    .split(',')[0]
                    .split(':')[1]
                    .split(';')[0]

                const imageId = uuidv4()
                const blob = new Blob([byteString], { type: mimeString })
                const file = this.blobToFile(
                    blob,
                    base64.name ? base64.name : imageId
                )
                resolve(file)
            })
        },
        blobToFile(blob, fileName) {
            return new File([blob], fileName, {
                type: blob.type,
                lastModified: new Date().getTime(),
            })
        },
        loadImage: function(src) {
            return new Promise(resolve => {
                const img = new Image()
                img.src = src
                img.onload = () => resolve(img)
            })
        },
        toBlob: function(canvas) {
            return new Promise(resolve => {
                canvas.toBlob(blob => resolve(blob), 'image/jpeg')
            })
        },
        async generatePreDesign() {
            try {
                this.loading2 = true
                await this.savePreDesign()
                const { pdf, preDesign } = await this.print()
                let pdfFile = this.blobToFile(pdf, this.preDesign.name)

                this.$emit(
                    'generatePreDesign',
                    this.cost,
                    preDesign,
                    pdfFile,
                    this.hours
                )
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading2 = false
            }
        },
        convertToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => resolve(reader.result)
                reader.onerror = error => reject(error)
                reader.readAsDataURL(file)
            })
        },
        getBase64ImageDimensions(base64Images) {
            const promises = base64Images.map(base64 => {
                return new Promise((resolve, reject) => {
                    const img = new Image()
                    img.onload = () => {
                        resolve({
                            src: base64.base64,
                            width: img.naturalWidth,
                            height: img.naturalHeight,
                        })
                    }

                    img.onerror = () => {
                        reject(`Error loading base64 image: ${base64}`)
                    }

                    img.src = base64.base64
                })
            })
            return Promise.all(promises)
        },
        async print() {
            try {
                this.loading = true
                let preDesign = _.cloneDeep(this.preDesign)
                preDesign.images = await this.extractImagesWithMatchAll(
                    this.editorContent
                )
                preDesign.images = await this.getBase64ImageDimensions(
                    preDesign.images
                )
                preDesign.structuralSet = this.structuralSet
                    ? this.structuralSet
                    : ''
                preDesign.windTunnelReport = this.windTunnelReport
                    ? this.windTunnelReport
                    : ''
                preDesign.notes = this.notes ? this.notes : ''
                preDesign.windCodeStd = this.windCodeStd ? this.windCodeStd : ''
                preDesign.criteriaFields = this.criteriaFields
                preDesign.date = this.formatDatePreDesign(this.dateToday)
                preDesign.code = this.code
                preDesign.engineeringDate =
                    this.cost.engineeringDate?._seconds ||
                    this.cost.engineeringDate?.seconds
                        ? this.formatDate(
                              this.cost.engineeringDate.seconds ||
                                  this.cost.engineeringDate._seconds
                          )
                        : ''
                preDesign.engineeringDate = this.formatDatePreDesign(
                    preDesign.engineeringDate
                )
                preDesign.engineer = this.engineer
                preDesign.estimator = this.cost.quote?.estimatorId
                    ? this.getUserName(this.cost.quote.estimatorId)
                    : ''

                preDesign.versionNumber = this.versionNumber
                preDesign.name = this.namePreDesignFile(
                    this.cost.reference,
                    preDesign.versionNumber
                )
                preDesign.location =
                    this.cost.quote?.city && this.cost.quote?.state
                        ? `${this.cost.quote.city}/${this.cost.quote.state}`
                        : ''
                const pdf = await printPreDesignPDF(preDesign, this.cost)
                return { pdf, preDesign }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        formatDatePreDesign(date) {
            if (!date) {
                return ''
            }
            const [year, month, day] = date.split('-')
            if (year && month && day) {
                return `${day}/${month}/${year}`
            } else {
                return ''
            }
        },
        close() {
            this.$emit('close')
        },
        formatDate(seconds = undefined) {
            return `${moment.unix(seconds).format('YYYY-MM-DD')}`
        },
        openAlertDialog() {
            this.alertDialog = true
        },
        closeAlertDialog() {
            this.alertDialog = false
            this.hours = null
        },
        openAlertToSend(item) {
            if (item) {
                this.selectedElementItem = _.cloneDeep(item)
                this.editingCostItem = this.elementItems.findIndex(
                    i => i.id === item.id
                )
                this.costItem = _.cloneDeep(this.selectedCostItem)
            }
            this.title = this.section.reference
                ? this.section.reference
                : this.section.name
            // Alphabetical order of items
            this.items = this.section.costItems.sort((a, b) => {
                if (a.name > b.name) {
                    return 1
                } else if (a.name < b.name) {
                    return -1
                } else {
                    return 0
                }
            })

            this.preDesignForm = true
        },
        namePreDesignFile(reference, versionNumber) {
            const currentDateTime = moment().format('YYYY-MM-DD HH:mm')
            const generatedName = `${currentDateTime} PreDesign ${reference} V${versionNumber}`
            return generatedName
        },
        closeAddElementDialog() {
            this.selectedPackage = null
            this.deleteDialog = false
        },
        saveElementItems(elementType, elementItems) {
            const element = {
                type: elementType,
                items: elementItems ? elementItems : {},
            }
            let indexElement = -1
            indexElement = this.preDesign.elements.findIndex(
                e => e.items.id == elementItems.id
            )

            if (indexElement >= 0) {
                this.preDesign.elements.splice(indexElement, 1, element)
            } else {
                this.preDesign.elements.push(element)
            }
            elementType = ''
            elementItems = {}
        },
        deleteElementItems(elementItems) {
            const indexElement = this.preDesign.elements.findIndex(
                element => element.items.id == elementItems.id
            )
            if (indexElement >= 0) {
                this.preDesign.elements.splice(indexElement, 1)
            }
        },
        focusTest() {
            this.$nextTick(() => {
                const autocompletes = this.$refs.autocomplete
                autocompletes.$children.forEach(autocomplete => {
                    autocomplete.focus()
                    setTimeout(() => {
                        autocomplete.blur()
                    })
                }, 200)
            })
        },
        async savePreDesign() {
            try {
                this.loading2 = true
                this.loading = true
                delete this.preDesign.date
                this.preDesign.versionNumber =
                    this.versionNumber && Number(this.versionNumber)
                        ? Number(this.versionNumber)
                        : ''
                this.preDesign.structuralSet = this.structuralSet
                    ? this.structuralSet
                    : ''
                this.preDesign.windTunnelReport = this.windTunnelReport
                    ? this.windTunnelReport
                    : ''
                this.preDesign.windCodeStd = this.windCodeStd
                    ? this.windCodeStd
                    : ''
                this.preDesign.name = this.namePreDesignFile(
                    this.cost.reference,
                    this.preDesign.versionNumber
                )
                this.preDesign.notes = this.notes ? this.notes : ''
                this.preDesign.criteriaFields = this.criteriaFields
                this.preDesign.criteriaFields.forEach(criterion => {
                    criterion.unit =
                        criterion.criteria.includes('Category') &&
                        criterion.value
                            ? 'category'
                            : !criterion.criteria.includes('Category')
                            ? criterion.unit || ''
                            : ''

                    criterion.value =
                        criterion.value && Number(criterion.value)
                            ? Number(criterion.value)
                            : criterion.value
                            ? criterion.value || ''
                            : ''
                })
                const images = this.extractImagesWithMatchAll(
                    this.editorContent
                )

                if (
                    this.preDesign.images &&
                    this.imagesAsFiles &&
                    images.length > 0
                ) {
                    const imagesToSave = this.imagesAsFiles.filter(
                        image => !this.originalImages.includes(image.name)
                    )
                    const path = `${this.companyId}/quotes/${this.cost.quoteId}/costs/${this.cost.document_id}/preDesigns`
                    for (const img of imagesToSave) {
                        await saveFile(img.src, path)
                    }
                    this.preDesign.images = []
                    const imagesAsFiles = this.imagesAsFiles
                    for (const img of imagesAsFiles) {
                        const index = this.imagesAsFiles
                            ? this.imagesAsFiles.findIndex(
                                  image =>
                                      image.name == img.src.name ||
                                      image.src.name === img.src.name
                              )
                            : -1
                        if (index > -1) {
                            if (this.preDesign.images?.length > 0) {
                                this.preDesign.images.push({
                                    name: img.name ? img.name : img.src.name,
                                    index,
                                })
                            } else {
                                this.preDesign.images = [
                                    {
                                        name: img.name
                                            ? img.name
                                            : img.src.name,
                                        index,
                                    },
                                ]
                            }
                        }
                    }
                } else {
                    this.preDesign.images = []
                }
                this.$emit('savePreDesign', this.preDesign)
            } catch (error) {
                console.log(error)
            } finally {
                this.loading2 = false
            }
        },
        importPreDesign(key) {
            const item = this.importOptions[key]
            delete item.createdOn
            delete item.engineer
            delete item.generatedOn
            delete item.id
            delete item.name
            delete item.rejectedOn
            delete item.rejectedBy
            delete item.rejectionReason
            delete item.versionNumber
            delete item.date
            delete item.file
            delete item.userId
            this.preDesign = { ...this.preDesign, ...item }
            this.criteriaFields = this.preDesign.criteriaFields
                ? this.preDesign.criteriaFields
                : this.criteriaFields
            this.structuralSet = this.preDesign.structuralSet
            this.windTunnelReport = this.preDesign.windTunnelReport
            this.windCodeStd = this.preDesign.windCodeStd
            this.notes = this.preDesign.notes
            this.importDialog = false
        },
    },
}
</script>

<style scoped>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.custom-table .v-data-table__wrapper tr {
    height: 30px; /* Ajusta según sea necesario */
}

.custom-table .v-data-table__wrapper td,
.custom-table .v-data-table__wrapper th {
    padding: 4px; /* Ajusta los márgenes internos */
}
</style>
